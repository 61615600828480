html,
body {
    height: 100%;
    background-color: #f8f9fa !important;
}

.form-signin {
    max-width: 330px;
    padding: 1rem;
    height: 200px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}



